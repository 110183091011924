export const clientCodeToUrl = (clientCode: string) => {
  const currentUrl = new URL(window.location.href);

  if (currentUrl.searchParams.has('code')) {
    currentUrl.searchParams.set('code', clientCode);
  } else {
    currentUrl.searchParams.append('code', clientCode);
  }

  const newUrl = currentUrl.toString();

  window.history.pushState({ path: newUrl }, '', newUrl);
};
